.MuiSlider {
  &-valueLabel {
    background-color: transparent;
    top: 35px;
    &:before {
      display: none;
    }
    &Label {
      font-size: 13px;
      color: $purple;
      font-family: Poppins, sans-serif;
      font-weight: 600;
    }
  }
  &-thumb {
    background-color: $white;
    border: 2px solid $purple;
  }
  &-rail {
    background-color: $gray;
  }
}
