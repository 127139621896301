@import "./variables/";
@import "./shared/";
// Components
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "./components/form";
@import "./components/formSell";
@import "./components/header";
@import "./components/footer";
@import "./components/button";
@import "./components/head";
@import "./components/loading";
@import "./components/intro";
@import "./components/cards";
@import "./components/latest";
@import "./components/blocks";
@import "./components/pagination";
@import "./components/product";
@import "./components/agents";
@import "./components/slider";
@import "./components/blockSimulation";
@import "./components/buyFilter";
@import "./components/tooltip";
@import "./components/LegalPolicy";

body {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: $black;
}
a {
  // text-decoration: none;
}
.new-line {
  display: inline-block;
}
.highlight {
  color: $purpleLight;
}

.hidden {
  visibility: hidden;
  position: absolute !important;
}
.error-wrapper{
  width: 1015px;
  position: relative;
  text-align: center;
  img{
    width: 100%;
    background-size: cover;
  }
  .error-text-wrapper{
    text-align: center;
    padding: 20px;
    h2{
      font-size: 2em;
      color: #2C4395;
    }
    p{
      max-width: 70%;
      text-align: center;
      margin: 15px auto;
      color: #2C4395;
    }
  }
  a{
    color: var(--bleu, #2C4395);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-decoration: none;
    border-radius: 4px;
    border: 1px solid var(--bleu, #2C4395);
    padding: 10px 20px;
  }
}
.reassurance{
  margin-top: 100px;
  background-size: cover;
  height: 92px;
  width: 100%;
}

.popup-info {
  position: fixed;
  top: 2%;
  right: 2%;
  background: linear-gradient(to right, #00b09b, rgba(53, 121, 187, 0.78));
  padding: 20px;
  border-radius: 10px;
  opacity: 0;
  display: none;
  transition: 10s;
}

.popup-info.show {
  display: block;
  animation: fadeIn 10s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

