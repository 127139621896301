.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 33px;
}
.pagination {
  display: flex;
  .page-item {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    border: 1px solid #dee2e7;
    color: #1c1c1c;
    cursor: pointer;
    a {
      display: inline-block;
      padding: 10px 15px;
      line-height: 15px;
    }
    &:last-child,
    &:first-child {
      max-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.active {
      border-color: #dee2e7;
      background-color: #f0f4ff;
    }
    &.disabled {
      a {
        opacity: 0.3;
      }
    }
  }
  &-list {
    .page-item {
      display: none;
      &:first-child {
        margin-right: 18px;
      }
    }
  }
  @media (max-width: 899px) {
    .page-item {
      a {
        padding: 5px 10px;
        line-height: 10px;
      }
    }
  }
}
