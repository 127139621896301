footer {
  background-color: $white;
  color: $black;
  padding: 30px 25px;
  .footer-bottom {
    margin-top: 40px;
    .nav-list {
      ul {
        display: flex;
      }
      li {
        margin-right: 25px;
        text-align: center;
      }
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-right: 8px;
        text-decoration: none;
        color: $black;
      }
    }
    .nav-social {
      ul {
        display: flex;
        margin-left: auto;
        justify-content: flex-end;
      }
      li {
        margin-left: 40px;
      }
      svg {
        color: $black;
      }
    }
    .cr {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      opacity: 0.5;
    }
    .footer-privacy {
      color: $black;
      border-top: 1px solid $black;
      padding-top: 22px;
      margin-top: 22px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          margin-right: 15px;
        }
        a {
          color: $black;
          text-decoration: none;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .footer-logo-dk {
        text-align: center;
        img {
          width: 120px;
          padding: 8px;
        }
      }
      .footer-logo-mb {
        display: none;
        img {
          width: 100%;
          max-width: 200px;
          padding: 10px;
        }
      }
    }
  }

  @media (max-width: 936px) {
    .footer-bottom {

    .nav-list {
      a {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            margin-right: 8px;
            text-decoration: none;
            color: $black;
      }
   }
  }
}

  @media (max-width: 899px) {
    .footer-bottom {
      .nav-list {
        justify-content: flex-start;
        ul {
          flex-direction: column;
          align-items: center;
        }
        li {
          text-align: left;
        }
        a {
          padding: 5px 0;
          display: block;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .nav-social {
        ul {
          justify-content: flex-start;
        }
        li {
          margin-left: 0;
          margin-right: 40px;
        }
      }
      .footer-privacy {
        text-align: center;
        .footer-logo-dk {
          display: none;
        }
        .footer-logo-mb {
          justify-content: center;
          display: flex;
        }
        ul {
          margin: auto;
          justify-content: center;
          margin-top: 20px;
          li {
            margin-left: 0;
            margin-right: 15px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
