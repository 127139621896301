.latest {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100px;
    background-image: url(../../../public/images/reassurance-bg.svg);
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: cover;
  }
  &-wrapper {
    position: relative;
    padding-bottom: 130px;
    > div:nth-child(1) {
      img {
        height: auto;
      }
      .loading-wrapper {
        height: 300px;
        max-height: 100%;
      }
      margin-bottom: auto;
    }
  }
  h2 {
    margin: 30px 0;
    color: $purple;
    font-size: 50px;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0em;
    .small {
      font-weight: 700;
      font-size: 16px;
      line-height: 15px;
      display: block;
    }
  }
  &-actions {
    .btn {
      margin: auto;
    }
  }
  @media (max-width: 899px) {
    h2 {
      font-size: 26px;
      line-height: 30px;
      .small {
        font-size: 14px;
        line-height: 15px;
      }
    }
  }
}