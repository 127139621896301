.intro {
  padding: 180px 10px 110px;
  background-color: $blueLight;
  background-image: url(../../../public//images/intro-bg.svg);
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;
  background-size: 1800px;
  > div {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      top: -14px;
      right: -54px;
      display: block;
      width: 600px;
      height: 100%;
      background-image: url(../../../public/images/family-car.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
    color: $purple;
    max-width: 650px;
    position: relative;
    z-index: 1;
  }
  .forms-toggler {
    margin-top: 230px;
    width: 100%;
    position: relative;
    z-index: 1;
    .form-navigator {
      ul {
        display: flex;
        li {
          color: $white;
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          padding: 24px 4px 18px;
          max-width: 284px;
          width: 100%;
          text-align: center;
          border-radius: 20px 20px 0 0;
          cursor: pointer;
          &:nth-child(1) {
            background-color: #7879f1;
          }
          &:nth-child(2) {
            background-color: #8e61c2;
          }
        }
      }
    }
    .forms-wrapper {
      display: flex;
    }
    .form-content-wrapper {
      width: 100%;
      box-sizing: border-box;
      background-color: $gray;
      border-top-right-radius: 20px;
      &-buy {
        background: linear-gradient(170.12deg, #7879f1 0%, #d3d4ff 100%);
      }
      &-sell {
        background: linear-gradient(170.12deg, #8e61c2 0%, #d8bff4 100%);
      }
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 80px 20px 90px;
    }
    .form-wrapper {
      background: $white;
      border-radius: 16px;
      width: 100%;
      max-width: 1090px;
      margin: auto;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  .form-content {
    color: $white;
    margin: 30px auto;
    width: 100%;
    max-width: 1090px;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;
    text-align: justify;
    h2 {
      width: 100%;
      max-width: 620px;
      font-size: 50px;
      font-weight: 700;
      line-height: 55px;
      .small {
        font-size: 16px;
        font-weight: 700;
        line-height: 15px;
        display: block;
      }
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      text-align: justify;
    }
    .btn {
      color: $white !important;
      border-color: $white !important;
      margin-left: 0;
      .text-wrapper {
        font-size: 18px;
        font-weight: bold;
      }
      .icon-wrapper {
        border-color: $white !important;
      }
    }
  }
  @media (max-width: 899px) {
    padding: 36px 10px 40px;
    background-size: 922px;
    background-position: 5px 0px;
    h1 {
      font-size: 24px;
      line-height: 28px;
    }
    .forms-toggler {
      .form-content-wrapper {
        padding: 80px 10px 0;
      }
      .form-content {
        h2 {
          font-size: 26px;
          line-height: 30px;
        }
        p {
          font-size: 14px;
          line-height: 18px;
        }
        .btn {
          width: 400px;
          max-width: 100%;
          margin: 0 auto 0 0;
        }
      }
      .form-wrapper {
        padding: 10px;
      }
    }
  }
  @media (max-width: 800px) {
    .form-content {
      h2 {
        text-align: left;
      }
    }
  }
  @media (max-width: 524px) {
    > div {
      position: relative;
      &:before {
        top: 38px;
        right: 0px;
        width: 100%;
      }
    }
  }
}
