.agents {
  .wrapper {
    padding: 0 20px;
    width: 100%;
    margin: 80px 0;
  }
  .cards {
    display: flex;
    margin: 120px 0 40px;
    flex-wrap: wrap;
    h5 {
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      color: $blueDark;
      display: block;
      width: 100%;
      margin-bottom: 15px;
    }
    .card {
      width: 31%;
      min-width: 300px;
      margin-bottom: 15px;
      margin-right: 2.3%;
      .card-wrapper {
        background-color: $blueLight;
        padding: 28px 20px;
        border-radius: 15px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      }
      .top {
        display: flex;
        border-bottom: 1px solid $blueDark;
        padding: 15px 0;
        .name {
          padding-top: 15px;
          color: #8838b9;
          display: flex;
          font-size: 24px;
          .firstname {
            font-weight: 700;
            margin-right: 4px;
          }
          .lastname {
            font-weight: 300;
          }
        }
      }
      .bottom {
        .city {
          text-transform: uppercase;
          font-weight: 400;
          font-size: 12px;
          color: #3c58bc;
          margin: 15px 0;
        }
      }
    }
  }
  @media (max-width: 688px) {
    .wrapper {
      margin: 40px 0;
    }
    .cards {
      flex-direction: column;
      margin: 40px 0;
      .card {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
