.head {
  color: $white;
  background: $purpalGradient;
  position: relative;
  &-light {
    background: $blueGradient;
  }
  &-orange {
    background: $orangeGradient;
  }
  > div {
    position: relative;
    padding: 75px 20px;
    &:after {
      content: "";
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      background-image: url(../../../public/images/header-circle.png);
      background-repeat: no-repeat;
      background-position: 100% 100%;
      background-size: 300px;
    }
  }
  .wrapper {
    width: 55%;
    display: flex;
    justify-content: space-between;
    // align-items: center;
  }
  h1 {
    font-weight: 900;
    font-size: 60px;
    line-height: 68px;
  }
  @media (max-width: 899px) {
    > div {
      &:after {
        background-size: 120px;
      }
    }
    .wrapper {
      flex-direction: column;
      width: 100%;
    }
    h1 {
      font-size: 28px;
      line-height: 32px;
    }
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 26px;
      line-height: 32px;
    }
  }
}
