.block {
  &-title {
    color: $purple;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
  }
  &-subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 55px;
    font-style: italic;
    color: #8838b9;
  }
  &-product {
    background: rgba($blueLight, 0.3);
    .head {
      background-image: url(../../../public/images/reassurance-bg.svg);
      background-size: cover;
      height: 92px;
    }
    .link-back {
      font-weight: 400;
      font-size: 21.2394px;
      line-height: 24px;
      color: $black;
      text-decoration: none;
    }
  }
  &-steps {
    background: $blueLight;
    .cards-wrapper {
      margin: 30px 0;
      display: flex;
      flex-wrap: wrap;
    }
    &-content {
      position: relative;
      max-width: 90%;
      margin: auto;
    }
  }
  &-partners {
    .block-title {
      text-align: center;
      margin-bottom: 30px;
    }
    .cards-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  &-form {
    > div {
      background-color: rgba($blueLight, 0.3);
      padding: 60px 0;
      border-radius: 15px;
    }
    padding: 60px 0;
    h3 {
      font-weight: 700;
      font-size: 50px;
      line-height: 55px;
      letter-spacing: 0;
      color: $purple;
    }
    .btn {
      background-color: $purpleLight !important;
    }
    @media (max-width: 899px) {
      h3 {
        font-size: 26px;
        line-height: 55px;
      }
    }
  }
  &-image {
    background-color: rgba($blueLight, 0.3);
    &.block-guid {
      background: $white;
    }
    img {
      width: auto;
      max-width: 100%;
    }
    &-top {
      img {
        margin-top: -130px;
        position: relative;
        z-index: 10;
      }
      background-color: transparent;
    }
    .title {
      h2 {
        margin: 30px 0;
        color: $purple;
        font-size: 50px;
        font-weight: 700;
        line-height: 58px;
        letter-spacing: 0em;
        .small {
          font-weight: 700;
          font-size: 16px;
          line-height: 15px;
          display: block;
        }
      }
    }
    .block-body {
      width: 70%;
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        text-align: justify;
      }
    }
    .actions {
      .btn {
        margin-left: 0;
      }
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;
      .actions-wrapper {
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn {
        width: auto;
        background-color: $purpleLight !important;
        border-radius: 4px;
      }
      @media (max-width: 684px) {
        .block-body {
          width: 100%;
        }
        .card,
        .actions-wrapper {
          width: 100%;
        }
      }
    }
    @media (max-width: 899px) {
      &-top {
        img {
          margin-top: 0;
        }
      }
      .block-body {
        width: 100%;
        p {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .title {
        h2 {
          font-size: 26px;
          line-height: 30px;
        }
        .small {
          font-size: 14px;
          line-height: 15px;
          margin-bottom: 10px;
        }
      }
    }
  }
  &-image-wrapperd {
    background-color: $white;
    .content {
      padding: 20px;
      font-weight: 400;
      font-size: 18px;
      line-height: 44px;

      text-align: justify;
      img {
        width: 100%;
        max-width: 450px;
        &.img-dk {
          display: block;
          float: right;
          padding-left: 20px;
        }
        &.img-mb {
          display: none;
        }
      }
      ul {
        li {
          position: relative;
          &:before {
            content: "-";
            display: inline-block;
            position: relative;
            top: 2px;
            font-size: 26px;
            color: #8838b9;
            margin-right: 20px;
          }
        }
      }
    }
    @media (max-width: 916px) {
      .content {
        padding: 5px;
        font-size: 14px;
        line-height: 20px;
        p, li {
          margin-bottom: 10px;
        }
        img {
          &.img-dk {
            display: none;
          }
          &.img-mb {
            margin-top: 20px;
            display: block;
          }
        }
      }
    }
  }
  &-other {
    background: $blueLight;
    padding: 0 20px;
    position: relative;
    overflow: hidden;
    margin-bottom: 100px !important;
    &:after {
      content: "";
      top: -20%;
      left: -20px;
      width: 260px;
      height: 130%;
      position: absolute;
      background-image: url(../../../public/images/half-circle.svg);
      background-repeat: no-repeat;
      background-position: -150px 0%;
      z-index: 0;
      transform: rotateZ(345deg);
      transform-origin: center;
    }
    .content-wrapper {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      h3 {
        .small {
          display: block;
          font-weight: 700;
          font-size: 16px;
          line-height: 15px;
        }
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 55px;
        color: $purple;
      }
    }
    @media (max-width: 1371px) {
      &:after {
        top: -5%;
        height: 150%;
      }
    }
    @media (max-width: 899px) {
      margin-bottom: 0 !important;
      .content-wrapper {
        h3 {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        flex-direction: column;
      }
      .btn {
        margin-left: 0;
      }
      &:after {
        display: none;
      }
    }
  }
  @media (max-width: 899px) {
    &-title {
      font-size: 30px;
      line-height: 34px;
    }
    &-subtitle {
      font-size: 18px;
      line-height: 20px;
    }
  }
}
