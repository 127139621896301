.tooltip,
.indecator {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
  .icon {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #2c4395;
    width: 16px;
    height: 16px;
    border: 2px solid #2c4395;
    border-radius: 15px;
    margin-left: 10px;
    &:hover {
      ~ .details {
        display: block;
      }
    }
  }
  .details {
    border: 2px solid #2c4395;
    border-radius: 8px;
    // border-top-left-radius: 0;
    position: absolute;
    z-index: 5;
    // top: 5px;
    top: 25px;
    left: 0;
    max-width: 300px;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2c4395;
    background-color: #f0f4ff;
    padding: 26px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    display: none;
    &:before {
      content: "";
      top: -0px;
      left: -20px;
      border-right-width: 0;
      background-color: #f0f4ff;
      // display: block;
      display: none;
      position: absolute;
      width: 20px;
      height: 20px;
      z-index: 3;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 28px;
      clip-path: polygon(100% 0, 0 0, 100% 100%);
    }
    &:after {
      content: "";
      top: -2px;
      left: -24px;
      border-right-width: 0;
      background-color: #2c4395;
      //display: block;
      display: none;
      position: absolute;
      width: 24px;
      height: 24px;
      z-index: 2;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 28px;
      clip-path: polygon(100% 0, 0 0, 100% 100%);
    }
    > b {
      display: block;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 8px;
    }
    span,
    ul li {
      font-size: 10px;
      line-height: 14px;
    }
    span {
      display: block;
    }
    ul {
      list-style: disc;
      padding-left: 20px;
      margin-top: 4px;
      margin-bottom: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
  &-static {
    display: block;
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    .details {
      max-width: 300px;
      width: calc(100% - 80px);
    }
  }
}
