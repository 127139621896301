$white: #fff;
$white2: #f1f1f1;
$black: #1b1b1b;
$gray: #aaabb3;
$purple: #2c4395;
$purpleLight: #8838b9;
$purpalGradient: linear-gradient(90deg, #2c4395 0%, #73509b 100%);
$purpalLightGradient: linear-gradient(
  170.12deg,
  #8e61c2 6.02%,
  #d8bff4 145.63%
);
$blueGradient: linear-gradient(170.12deg, #7879f1 6.02%, #d3d4ff 145.63%);
$blue: #7879f1;
$blueDark: #3c58bc;
$blueLight: #f0f4ff;
$blueLighten: rgba(245, 239, 252, 0.4);

$orange: #fe9727;
$orangeLight: #ffe4c7;
$orangeGradient: linear-gradient(102.65deg, #ff9c28 0%, #e13600 127.65%);
