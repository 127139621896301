.form {
  &-title {
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    line-height: 26px;
    padding: 30px 0;
    border-bottom: 1px solid $purple;
    margin-top: 35px;
    margin-bottom: 30px;
    color: $purple;
  }
  &-label {
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0.05em;
    margin: 6px 0;
  }
  &-info {
    display: flex;
    margin-bottom: 10px;
    .icon {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #2c4395;
      min-width: 16px;
      min-height: 16px;
      width: 16px;
      height: 16px;
      border: 2px solid #2c4395;
      border-radius: 15px;
      margin-right: 10px;
    }
    .details {
      font-size: 12px;
      line-height: 20px;
      color: #2c4395;
    }
  }
  &-controllers {
    > div {
      position: relative;
      margin-top: 0;
    }
  }
  &-control {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.05em;
    width: 100%;
    // height: 50px;
    margin: 6px 0;
    padding: 10px;
    background-color: $white;
    border: 1px solid $black;
    &[type="checkbox"],
    &[type="radio"] {
      position: absolute;
      top: 14px;
      left: 0;
    }
    > div {
      border: none;
    }
    &-select {
      * {
        outline: none;
        border: none;
        // color: $black !important;
      }
      .react-select {
        &__menu {
          background: $white2;
        }
        &__control {
          background: transparent;
          padding-top: 5px;
        }
      }
      svg {
        fill: $black;
      }
    }
    &-slider {
      > span {
        padding: 0;
        top: -6px;
      }
      > div {
        width: 100%;
      }
      .values {
        display: flex;
        position: relative;
        top: -14px;
        justify-content: space-between;
        .value {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    &::placeholder {
      color: $gray;
    }
    &-wrapper {
      &-checkbox,
      &-radio {
        position: relative;
        padding-left: 20px !important;
        margin-top: 6px !important;
        margin-bottom: 6px !important;
      }
    }
    &-info {
      display: inline-block;
      color: $white;
      margin-left: 10px;
      z-index: 2;
      &:hover {
        .info-content {
          display: block;
        }
      }
      .icon {
        background-color: $black;
        color: $white;
        font-family: sans-serif;
        width: 18px;
        height: 18px;
        text-align: center;
        border-radius: 10px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .info-content {
        position: absolute;
        z-index: 1;
        background-color: $black;
        border-radius: 3px;
        width: 150px;
        padding: 10px;
        display: none;
        font-size: 12px;
      }
    }
  }
  .form-captch {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .error {
    color: red;
    display: block;
    font-size: 15px;
    margin-bottom: 8px;
    &-captch {
      margin-top: 10px;
    }
  }
  label {
    position: relative;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.05em;
    &.tandc {
      font-size: 12px;
      line-height: 16px;
    }
  }
  input {
    position: relative;
    &[type="checkbox"],
    &[type="radio"] {
      margin-bottom: 15px;
      &:checked {
        &:after {
          opacity: 1;
        }
      }
      background-color: blue;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin-left: 20px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 10px;
        background: $white;
        z-index: 0;
        left: -20px;
        top: -14px;
        border: 1px solid $purple;
      }
      &:after {
        content: "";
        opacity: 0;
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: green;
        z-index: 0;
        left: -16px;
        top: -10px;
      }
    }
    &[type="checkbox"] {
      &:before {
        border-radius: 0;
      }
    }
  }
  .btn {
    margin: 10px 0 30px auto;
  }
  &-sell,
  &-buy {
    margin-top: 0 !important;
    background-color: $white;
    .form-label {
      margin-bottom: 6px;
    }
    .form-controllers {
      display: flex;
      align-items: stretch;
    }
    .form-control-wrapper {
      margin: 0 8px;
      flex: 1;
      background: $white2;
      // height: 80px;
      padding: 15px 24px 0;
      border-radius: 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
    }

    .form-control {
      border: none;
      background-color: $white2;
      padding: 0;
      margin: 0;
      outline: none;
      &::placeholder {
        color: $gray;
      }
      &-select {
        .react-select__control {
          box-shadow: none;
          margin-top: -8px;
          position: relative;
        }
        .react-select__input-container {
          padding-top: 0;
        }
        .react-select__placeholder {
          color: $gray !important;
          line-height: 20px;
        }
        .react-select__menu {
          width: 100%;
          padding: 0 !important;
        }
        .react-select__option {
          padding: 10px 10px;
        }
      }
      &-slider {
        position: relative;
        background-color: transparent;
        top: -5px;
      }
    }
    label {
      border: none;
      font-size: 16px;
      font-weight: 700;
      line-height: 15px;
      padding: 0;
      margin: 0 0 2px;
      color: $purple;
    }
    .form-actions {
      align-self: center;
    }
    .btn {
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 15px;
    }
    .error {
      position: absolute;
      bottom: -22px;
      font-size: 12px;
    }
    @media (max-width: 899px) {
      &:not(.form-buy-2) {
        position: relative;
        .form-actions {
          width: 70px;
          position: absolute;
          bottom: 10px;
          right: 0;
          .btn {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }
  &-buy {
    &-direct {
      .btn {
        width: 100%;
        max-width: 400px;
      }
    }
    @media (max-width: 899px) {
      &:not(.form-buy-2) {
        .form-controllers {
          .form-control-wrapper {
            padding: 15px 24px 2px;
            &:nth-child(3) {
              width: calc(100% - 90px);
              align-self: flex-start;
            }
          }
        }
      }
    }
  }
  &-sell {
    @media (max-width: 899px) {
      &:not(.form-sell-2) {
        .form-controllers {
          .form-control-wrapper {
            &:nth-child(2) {
              width: calc(100% - 90px);
              align-self: flex-start;
            }
          }
        }
      }
    }
  }
  &-sell-2 {
    .form-control-select {
      > div {
        background-color: $white;
      }
    }
  }
  &-buy-2 {
    margin-top: 12px;
    background: $white2;
    padding: 48px 20px;
    .form-buy {
      background-color: transparent;
    }
    .form-controllers {
      display: flex;
      flex-direction: column;
    }
    .form-control-wrapper {
      width: 100%;
      margin: 8px 0;
      background-color: transparent;
      label {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 20px;
        line-height: 173%;
        letter-spacing: 0.05em;
      }
    }
    .form-control {
      background-color: $white;
      padding: 8px 18px;
      min-height: 70px;
      &-select {
        display: block;
        > div {
          padding-top: 5px;
          padding-left: 8px;
          background-color: $white;
        }
      }
      &-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
    .form-actions {
      margin: 30px 0 0;
      padding: 0 15px;
      .btn {
        width: 100%;
        max-width: 100%;
      }
    }
    @media (max-width: 899px) {
      padding: 0 15px;
      position: relative;
      background: none;
      margin-bottom: 30px;
      box-sizing: border-box;
      .card-title {
        box-sizing: border-box;
        padding: 0 15px;
        margin-bottom: 0;
      }
      width: 100%;
      &.show {
        overflow: hidden;
        .form {
          height: 70vh;
        }
        .form-content-wrapper {
          height: 70vh;
          right: 0;
        }
      }
      .form-content-wrapper {
        box-sizing: border-box;
        background-color: $white2;
        width: 100%;
        padding: 40px 15px;
        position: absolute;
        top: -10px;
        right: -120%;
        overflow: scroll;
        transition: all 0.3s linear;
      }
      .form-control-wrapper {
        padding: 0;
      }
    }
    .error {
      margin-top: 8px;
    }
    @media (max-width: 1240px) {
      padding: 25px 15px;
      .form-control-wrapper {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  @media (max-width: 899px) {
    &-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      padding: 0px 0 10px;
      margin-bottom: 10px;
    }
    label {
      font-size: 18px;
      line-height: 31px;
      letter-spacing: 0.05em;
    }
    &-control {
      margin-bottom: 15px;
    }
    .btn {
      margin: 20px 0 20px auto;
    }
    &-sell,
    &-buy {
      .form-controllers {
        flex-direction: column;
        .form-control-wrapper {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}
