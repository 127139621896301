.block-product {
  .head {
    ~ div {
      padding: 0 20px;
    }
  }
  .fixed-wrapper {
    width: 100%;
    height: 100%;
    .product-details {
      position: sticky;
      top: 20px;
    }
  }
}
.product {
  .left-side {
    display: flex;
    flex-direction: column;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card-info {
      width: 45%;
      .card-wrapper {
        padding-right: 5%;
      }
    }
    @media (max-width: 969px) {
      .card-info {
        width: 100%;
        padding-right: 0;
      }
    }
  }
  &-images {
    margin-bottom: 45px;
    .active-image {
      padding: 20px 0;
    }
    .img {
      border-radius: 15px;
      max-width: 100%;
      width: 846px;
      height: 20vw;
      min-height: 300px;
      max-height: 530px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .other-images {
      ul {
        display: flex;
        align-items: center;
        li {
          width: 23%;
          margin-right: 1%;
          img {
            max-width: 100%;
            border-radius: 5px;
          }
        }
      }
    }
  }
  &-details {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.02);
    border-radius: 15px;
    // overflow: hidden;
    margin: 25px 0;
    background-color: $white;
    &-content {
      padding: 30px;
      img {
        max-width: 100%;
      }
    }
    .car-content {
      .top {
        flex-direction: row;
        margin-bottom: 25px;
        .name {
          font-weight: 500;
          font-size: 30px;
          line-height: 26px;
          margin-bottom: 5px;
        }
        .description {
          font-weight: 400;
          font-size: 20px;
          line-height: 34px;
          color: #aaabb3;
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        .price {
          font-weight: 600;
          font-size: 35.6071px;
          line-height: 26px;
          color: #8838b9;
          padding-right: 20px;
          margin-bottom: 20px;
        }
        .location {
          text-align: center;
          background-color: #f5effc;
          margin-bottom: 10px;
          padding: 20px 30px;
          .tooltip {
            position: absolute;
            right: 0;
            top: -8px;
            .icon {
              font-size: 12px;
              line-height: 16px;
              width: 14px;
              height: 14px;
            }
            .details {
              right: -40px;
              left: auto;
              width: 200px;
            }
          }
          p {
            margin: 4px auto;
            &:first-child {
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
              color: $purple;
            }
            &:last-child {
              position: relative;
              margin-top: 10px;
              font-weight: 700;
              font-size: 22px;
              line-height: 16px;
              text-align: center;
              color: #8838b9;
            }
          }
        }
      }
      .actions {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        .btn {
          width: 100%;
          max-width: 100%;
          margin: 0 0 10px 0;
        }
      }
      &-buy {
        .bottom {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
        }
        .list-specs {
          flex: 2;
          ul {
            list-style: none;
            font-size: 20px;
            line-height: 24px;
          }
          li {
            margin-bottom: 10px;
          }
          .key {
            color: $black;
            margin-right: 10px;
          }
          .value {
            color: #aaabb3;
          }
          padding-right: 10px;
        }
        .right {
          margin-top: 20px;
          min-width: 180px;
          .price {
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 35px;
          }
        }
        .info {
          margin-top: 20px;
          width: 85%;
          margin-left: auto;
          text-align: right;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          color: #666;
        }
      }
    }
    &-mb {
      display: none;
      @media (max-width: 899px) {
        display: block;
      }
      .car-content {
        .bottom {
          .location {
            .tooltip {
              top: -30px;
              right: -20px;
              .details {
                right: -30px;
              }
            }
          }
        }
      }
    }
    .list {
      &-title {
        margin: 10px 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: $purple;
      }
      ul {
        &:not(.pagination) {
          padding-left: 16px;
          padding-right: 10px;
          list-style: disc;
          height: 405px;
          li {
            padding-left: 5px;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #363636;
          }
        }
      }
    }
    .list-details {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        margin: 10px 0;
        width: 46%;
        min-width: 200px;
        p {
          display: flex;

          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0px;
        }
        .key {
          width: 70%;
        }
        .value {
          width: 30%;
          padding-left: 20px;
          font-weight: bold;
          text-transform: capitalize;
        }
      }
    }
    @media (max-width: 445px) {
      .car-content-buy .bottom {
        .right {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 899px) {
    .list-details {
      li {
        width: 100%;
      }
    }
    .list {
      li {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
