.legalWrapper{
  padding: 0 10px;
  margin-top: 30px;
  font-weight: 400;
  width: 100%;
  h3{
    font-weight: bolder;
    display: inline;
  }
  hr{
    border: 1px solid black;
  }
  div{
    p{
      margin: 15px 0;
      line-height: 22px;
      strong{
        font-weight: bold;
      }
    }
    span{
      text-decoration: underline;
    }
    table{
      font-weight: bold;
      margin-bottom: 15px;
    }
    tr{
      width: 100%;
      border: 2px solid black;
      td:nth-child(2){
        width: 30%;
      }
    }
    td{
      border: 2px solid black;
      padding: 15px;
      img{
        width: 80px;
        background-size: cover;
      }
      p{
        font-weight: 400;
      }
    }
    ul{
      list-style: disc;
      margin: 0 30px;
      line-height: 30px;
    }
  }
  .p-left{
    margin: 15px;
  }
  .w-content {
    width: fit-content;
  }
  .border{
    border: 1px solid black;
    padding: 10px;
    margin-top: 30px;
  }
  .imageWrapper{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    div{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }
}
