.block-simulation {
  background: #000;
  background-color: $white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.02);
  margin-bottom: 40px;
  .title {
    background: #f0f4ff;
    h4 {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      padding: 34px 40px;
      color: #2c4395;
    }
  }
  &-body {
    background: $white;
    padding: 34px 40px;
  }
  .slider {
    display: flex;
    margin: 30px 0 40px;
    .label {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #2c4395;
      width: 15%;
      min-width: 110px;
      padding-right: 20px;
    }
    .slider-container {
      width: 85%;
    }
    .MuiSlider-rail {
      height: 4px;
    }
    .MuiSlider-valueLabel {
      top: 0;
    }
    .MuiSlider-valueLabelLabel {
      font-size: 18px;
    }
    .MuiSlider-thumb {
      width: 20px;
      height: 20px;
      border-width: 3px;
    }
    .values {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        color: #cac9d7;
      }
    }
  }
  .btn {
    margin-left: auto;
    margin-bottom: 20px;
    padding: 18px 15px;
  }
  .packs {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    .pack {
      width: 48%;
      box-sizing: border-box;
      padding: 55px 60px;
      border-radius: 15px;
      border: 2px solid transparent;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.active {
        border-color: #2c4395;
      }
      h5 {
        font-size: 28px;
        line-height: 34px;
        font-weight: 500;
        margin-bottom: 30px;
        color: #2c4395;
      }
      ul {
        margin-bottom: 60px;
        li {
          position: relative;
          margin-bottom: 18px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          input {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            &:before {
              background: $white;
              content: "";
              width: 18px;
              height: 18px;
              border: 2px solid #2c4395;
              border-radius: 15px;
              display: inline-block;
              position: absolute;
              top: -4px;
              left: -4px;
            }
            &:after {
              display: none;
              background-image: url(../../../public/images/checked.png);
              background-size: contain;
              background-repeat: no-repeat;
              content: "";
              width: 25px;
              height: 25px;
              position: absolute;
              top: -8px;
              left: -3px;
            }
            &:checked {
              &:before {
                background-color: #5669aa;
              }
              &:after {
                display: block;
              }
            }
          }
        }
        label {
          padding-left: 40px;
          display: flex;
          // position: relative;
        }
      }
      .price {
        background-color: #f5effc;
        display: inline-block;
        color: #8838b9;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        padding: 22px;
        position: relative;
        span {
          font-size: 32px;
        }
        .tooltip {
          position: absolute;
          right: 10px;
          top: 8px;
          .details {
            left: unset;
            right: -40px;
            width: 200px;
          }
        }
      }
      .btn {
        margin-left: 0;
        margin-top: 15px;
        padding: 18px;
        width: 250px;
        .text-wrapper {
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          letter-spacing: 0.05em;
          margin: auto;
        }
        .icon-wrapper {
          display: none;
        }
      }
    }
    @media (max-width: 930px) {
      .pack {
        padding: 20px 30px;
        h5 {
          font-size: 22px;
          line-height: 26px;
        }
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
      .pack {
        width: 100%;
        margin-bottom: 20px;
        .btn {
          max-width: 100%;
        }
      }
    }
    @media (max-width: 683px) {
      .pack {
        .indecator {
          .details {
            max-width: 30vw !important;
          }
        }
      }
    }
    @media (max-width: 530px) {
      .pack {
        ul {
          .indecator {
            .details {
              left: -40vw;
              top: 30px;
              &:before,
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .solution {
    ul {
      margin-bottom: 25px;
      li {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.05em;
        display: flex;
        justify-content: space-between;
        color: #363636;
        b {
          width: 100%;
          font-weight: 700;
          display: flex;
          justify-content: space-between;
        }
        padding: 8px 10px;
      }
    }
    .btn {
      margin-left: 0;
      padding: 18px;
    }
    .details {
      color: #434343;
      h5 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 28px;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 16px;
        .small {
          font-size: 11px;
          line-height: 18px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .title {
      h4 {
        padding: 20px 25px;
        font-size: 20px;
      }
    }
    &-body {
      padding: 12px 20px;
    }
  }
}
