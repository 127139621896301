header {
  padding-top: 50px;
  padding-bottom: 30px;
  color: $white;

  .logo {
    width: 140px;
    margin-left: 20px;

    @media (max-width: 900px) {
      width: 100%;
      max-width: 150px;
      min-width: 90px;
    }
  }

  .nav-list {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-end;
      padding: 20px;

      .menu-toggler {
        position: absolute;
        top: -10px;
        right: 0px;
      }

      ul {
        padding-top: 36px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        text-align: center;

        li {
          display: none;
        }
      }
    }

    &.mobile-show {
      ul>li {
        display: flex;
        padding-top: 10px;
        margin-left: auto;
      }
    }
  }

  .menu-toggler {
    color: $black;
    background-color: $white;
    outline: none;
    display: none;
    border: none;

    @media (max-width: 900px) {
      display: block;
    }
  }

  ul {
    display: flex;

    li {
      justify-content: center;
      display: flex;
      text-align: center;
    }

    a {
      text-decoration: none;
      font-size: 15px;
      color: $black;
      margin: 5px;
      padding: 8px;
      line-height: 24px;
      position: relative;

      &.active {
        font-weight: 700;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        margin: auto;
        height: 2.5px;
        border-radius: 50px;
        /* Hauteur de la barre de soulignement */
        bottom: -1px;
        left: 0;
        background: linear-gradient(90deg, #2c4395 0%, #73509b 100%);
        /* Couleur de la barre de soulignement */
        transition: transform 0.3s ease-in-out;
        /* Animation de transition */
        transform-origin: center;
        /* Point d'origine de la transformation */
        transform: scaleX(0);
        /* Aucune largeur initiale */
      }

      &:hover::after {
        transform: scaleX(0.92);
        /* Largeur maximale de la barre de soulignement */
      }
    }
  }

  @media (max-width: 900px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}