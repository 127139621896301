.buy-filter {
  .message {
    font-size: 20px;
    padding: 20px 20px;
  }
  .indecate {
    font-size: 17px;
    font-weight: 700;
    line-height: 18px;
    color: $blueDark;
    text-align: right;
    padding: 0 0;
    margin: 25px 0;
  }
  @media (max-width: 900px) {
  .message {
    font-size: 18px;
    padding: 14px 14px;
  }
    .indecate {
      font-size: 14px;
      line-height: 16px;
    }

  }
}
