.card-link {
  text-decoration: none;
  // display: flex;
  height: 100%;
}

.cards-reassurance {
  background-image: linear-gradient(90deg, #2c4395 0%, #73509b 100%);
  padding-bottom: 30px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100px;
    background-image: url(../../../public/images/reassurance-bg.svg);
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: cover;
  }
  > div > div {
    margin-top: 0;
    margin-bottom: 0;
    z-index: 1;
  }
  @media (max-width: 899px) {
    &:before {
      height: 40px;
    }
  }
}
.card {
  text-decoration: none;
  background: $white;
  display: flex;
  flex-direction: column;
  position: relative;
  .loading-wrapper {
    background: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 165px;
    .loading {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  a {
    text-decoration: none;
  }
  &-info {
    margin-bottom: 20px;
    path {
      fill: #5d5fef;
    }
    .card-wrapper {
      border: 1px solid #f0f4ff;
      border-radius: 5px;
      padding: 20px 25px;
    }
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: $purple;
      margin: 5px 0;
    }
    .info {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
    }
  }
  &-step {
    width: 40%;
    margin: 50px 0;
    position: relative;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.02);
    &:nth-child(1) {
      padding-left: 0;
      padding-right: 10%;
    }
    &:nth-child(1),
    &:nth-child(2) {
      &:after {
        content: "";
        position: absolute;
        bottom: -52px;
        width: 100%;
        height: 3px;
        background-color: rgb(31, 81, 170);
      }
    }
    &:nth-child(3),
    &:nth-child(4) {
      padding-left: 10%;
      padding-right: 10%;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      &:after {
        content: "";
        position: absolute;
        top: -51px;
        left: -10%;
        width: 120%;
        height: 3px;
        background-color: rgb(31, 81, 170);
      }
      .card-wrapper {
        &:before {
          top: -50px;
        }
        &:after {
          top: -60px;
        }
      }
    }
    &:nth-child(5) {
      &:before {
        content: "";
        position: absolute;
        z-index: 2;
        top: -68px;
        right: calc(-10% - 10px);
        width: 35px;
        height: 35px;
        background-image: url("../../../public/images/arrowRight.svg");
        background-size: contain;
      }
    }
    .card-wrapper {
      height: 100%;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: calc(100% + 50px);
        background-color: $purple;
      }
      &:after {
        content: "";
        position: absolute;
        left: -8px;
        bottom: -60px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: $purple;
      }
    }
    .card-head {
      padding: 30px 40px;
      background: #e5ebff;
      font-weight: 600;
      font-size: 23.8943px;
      line-height: 24px;
      color: #1f51aa;
      border-radius: 15px 15px 0 0;
    }
    .card-body {
      background-color: $white;
      border-radius: 0 0 15px 15px;
      padding: 30px 48px;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: justify;
      color: #363636;
    }
    &-dk {
      display: flex;
    }
    &-mb {
      display: none;
    }
    @media (max-width: 767px) {
      padding: 0px !important;
      width: 100%;
      margin: 0;
      .card-wrapper {
        &:before,
        &:after {
          display: none;
        }
      }
      &:before,
      &:after {
        display: none;
      }
      &-dk {
        display: none;
      }
      &-mb {
        display: flex;
        margin-bottom: 25px;
      }
    }
    @media (max-width: 899px) {
      .card-head {
        font-size: 20px;
        line-height: 24px;
        padding: 20px 30px;
      }
      .card-body {
        padding: 20px 38px;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  &-partner {
    margin: 65px 0 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    .text-side {
      width: 60%;
    }
    .image-side {
      width: 20%;
      img {
        width: auto;
        max-width: 100%;
      }
    }
    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #9938bf;
      text-align: left;
      margin-top: 30px;
    }
    .card-body {
      margin: 27px 0 80px;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      text-align: justify;
    }
    .actions {
      .btn {
        margin: 27px 0;
      }
    }
    @media (max-width: 899px) {
      margin: 20px 0;
      &:first-child {
        margin-bottom: 40px;
      }
      .card-body {
        margin: 20px 0;
      }
      .text-side {
        margin-bottom: 0;
      }
    }
    @media (max-width: 767px) {
      .text-side,
      .image-side {
        width: 50%;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .btn {
        width: 100%;
      }
    }
    @media (max-width: 516px) {
      .text-side,
      .image-side {
        width: 100%;
        padding: 0 20px;
        text-align: center;
      }
      &:last-child {
        flex-direction: column-reverse;
      }
    }
  }
  &-reassurance {
    position: relative;
    z-index: 2;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    padding: 60px 40px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.19);
    border-radius: 5px;
    color: $white;
    text-align: center;
    .icon-wrapper {
      margin-bottom: 10px;
    }
    h3 {
      margin: 20px 0;
      font-weight: 700;
      font-size: 25px;
      line-height: 26px;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: justify;
    }
    @media (max-width: 899px) {
      margin-bottom: 15px;
    }
  }
  &-car {
    background: $white;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    height: 100%;
    position: relative;
    a {
      color: inherit;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: 180px;
      object-fit: cover;
      -o-object-fit: cover;
    }
    .details {
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .top {
      padding: 13px 18px 26px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // height: 140px;
      p:first-child {
        display: flex;
        flex-wrap: wrap;
        flex: 2;
      }
      .name {
        color: #363636;
        font-weight: 500;
        font-size: 20px;
        line-height: 16px;
        padding-right: 12px;
        margin-bottom: 5px;
        margin-top: 13px;
        min-width: 165px;
      }
      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #363636;
        opacity: 0.4;
        margin-top: 13px;
      }
      .price {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        font-weight: 600;
        font-size: 28px;
        line-height: 16px;
        color: $purpleLight;
        margin-top: 13px;
        .number {
          white-space: nowrap;
        }
        .coin {
          font-size: 12px;
        }
      }
    }
    .bottom {
      padding: 12px 20px;
      display: flex;
      // flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      background-color: $blueLight;
      height: 40px;
      p {
        color: #2c4395;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        &:first-child {
          font-weight: 700;
          font-size: 15px;
          letter-spacing: 0em;
          color: #2c4395;
          padding-right: 8px;
          flex: 12;
        }
        &:last-child {
          font-weight: 400;
          font-size: 16px;
          flex: 1;
        }
      }
    }
    &-2 {
      .top {
        padding: 20px;
        .name {
          font-size: 18px;
          line-height: 20px;
        }
        .price {
          font-weight: 600;
          font-size: 22px;
          line-height: 16px;
          color: $blueDark;
        }
      }
      .bottom {
        p {
          color: $blueDark !important;
        }
      }
    }
    &.card-orange {
      .bottom p,
      .description,
      .name {
        color: #4b4b4b !important;
      }
      .price {
        color: $orange;
      }
      .bottom {
        background-color: $orangeLight;
        color: $black;
      }
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
      .top {
        height: 110px;
        .name {
          font-size: 20px;
          line-height: 16px;
        }
        .description {
          font-size: 14px;
        }
        .price {
          font-size: 24px;
        }
      }
    }
  }
  &-product,
  &-title,
  &-buy {
    width: 48%;
    margin: 10px 0;
    border: 1px solid #e1cafc;
    border-radius: 5px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:nth-child(1) {
      .icon-wrapper {
        background-color: #324596;
      }
      .name {
        color: #324596;
      }
    }
    &:nth-child(2) {
      .icon-wrapper {
        background-color: #4f35a1;
      }
      .name {
        color: #4f35a1;
      }
    }
    &:nth-child(3) {
      .icon-wrapper {
        background-color: #6936ac;
      }
      .name {
        color: #6936ac;
      }
    }
    &:nth-child(4) {
      .icon-wrapper {
        background-color: #8438b7;
      }
      .name {
        color: #8438b7;
      }
    }
    &:nth-child(5) {
      .icon-wrapper {
        background-color: #a548d3;
      }
      .name {
        color: #a548d3;
      }
    }
    .details {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 30px 20px;
      background-color: $white;
      .icon-wrapper {
        padding: 12px;
        border-radius: 5px;
        margin-right: 20px;
      }
      p {
        &.name {
          font-weight: 700;
          font-size: 23.8943px;
          line-height: 26px;
        }
        &.description {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
    &.card-orange {
      .icon-wrapper {
        background-color: $orange !important;
      }
      .name {
        color: $orange !important;
      }
    }

    @media (max-width: 899px) {
      .details {
        p {
          &.name {
            font-size: 20px;
            line-height: 24px;
          }
          &.description {
            font-size: 16px;
          }
        }
      }
    }
  }
  &-product {
    width: 100%;
    border: none;
    margin: 0;
    .details {
      background-color: $blueLight;
      width: 100%;
      padding: 20px 30px;
      .icon-wrapper {
        background-color: $blue;
      }
      p {
        &.name {
          font-size: 24px;
          line-height: 28px;
          color: $blue;
        }
        &.description {
          font-size: 21px;
          line-height: 24px;
        }
      }
    }
    @media (max-width: 899px) {
      p {
        &.name {
          font-size: 20px;
          line-height: 24px;
        }
        &.description {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  &-title {
    border: none;
    width: 100%;
    background-color: transparent;
    .details {
      background-color: transparent !important;
      padding-left: 0;
      width: 100%;
    }
    .name {
      color: $blue !important;
    }
    .icon-wrapper {
      background-color: $blue !important;
    }
  }
}
.car-infos{
    position: absolute;
    display: flex;
    align-items: center;
    gap: 10px;
    top: 2%;
    right: 2%;
    span{
      background: #3c58bc;
      color: white;
      padding: 5px;
      border-radius: 10%;
    }
  }
